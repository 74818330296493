﻿.vx-container-overview{
    margin:5px;

}

.vx-sorted-table-header-desc {
    background-color: rgba(141, 192, 219, 0.25);
    -ms-text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
}

.vx-sorted-table-header-asc {
    background-color: rgba(141, 192, 219, 0.25);
    -ms-text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
}

.icon-label {
    width: 45px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-size: 13pt;
}

.not-important {
    -ms-opacity: 0.25;
    opacity: 0.25;
}
.fa-05x {
    font-size: 14pt;
}