﻿.vx-wizard {
    margin: 0;
    padding: 0;
    height: 135px;
}

.fixed-vx-wizard-steps{
    position: fixed;
    width: 33.33333333%;
    padding-right: 60px;
}
    .vx-wizard li {
        display: inline-block;
        width: 75px;
        height: 75px;
        margin: 5px 5px;
        border: 1px solid #000;
    }

.vx-wizard-badge {
    z-index: 100;
    margin-left: auto;
    margin-right: auto;
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 50%;
    text-align: center;
    font-size: 1.0em;
    line-height: 30px;
    color: #fff;
    background-color: #999999;
}


    .vx-wizard-badge.primary {
        background-color: #2e6da4 !important;
    }

    .vx-wizard-badge.success {
        background-color: #3f903f !important;
    }

    .vx-wizard-badge.warning {
        background-color: #f0ad4e !important;
    }

    .vx-wizard-badge.danger {
        background-color: #d9534f !important;
    }

    .vx-wizard-badge.info {
        background-color: #5bc0de !important;
    }
