$breakpoints: (
        'sm': 576px,
        'md': 768px,
        'lg': 992px,
        'xl': 1200px
);

@mixin make-col($size, $columns: 12) {
  flex: 0 0 percentage(calc($size / $columns));
  max-width: percentage(calc($size / $columns));
}

@mixin make-col-ready {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 8px;
  padding-left: 8px;
}

@mixin media-breakpoint-up($breakpoint, $breakpoints) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
  }
  @else {
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

.vx-container {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.vx-row {
  display: flex;
  flex-wrap: wrap;
  padding-left: 8px;
  padding-right: 8px;
}

.vx-column{
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 8px;
}

.vx-full-width{
  width: 100%;
}

.vx-no-wrap{
  flex-wrap: nowrap;
}

@each $breakpoint in map-keys($breakpoints) {
  $breakpoint-value: map-get($breakpoints, $breakpoint);

  @include media-breakpoint-up($breakpoint, $breakpoints) {
    @for $i from 1 through 12 {
      .vx-col-#{$breakpoint}-#{$i} {
        @include make-col-ready;
        @include make-col($i);
      }
    }
  }
}

.vx-pull-right{
  margin-left: auto;
}

.vx-pull-left{
  margin-right: auto;
}