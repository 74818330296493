﻿.vx-float-field-show-hide.ng-hide-add, .vx-float-field-show-hide.ng-hide-remove {
    -webkit-transition: all linear 0.2s;
    -moz-transition: all linear 0.2s;
    -o-transition: all linear 0.2s;
    transition: all linear 0.2s;
    display: block !important;
    position: absolute;
}

.vx-float-field-show-hide.ng-hide-add.ng-hide-add-active,
.vx-float-field-show-hide.ng-hide-remove {
    top: -8px;
    display:block!important;
    position: absolute;
    opacity: 0;

}

.vx-float-field-show-hide.ng-hide-add,
.vx-float-field-show-hide.ng-hide-remove.ng-hide-remove-active {
    top:-14px;
    opacity: 1;
    display:block!important;
    position: absolute;
}

.vx-float-field {
    position: relative;
    margin-bottom: 1em;
    padding-bottom: 1em;
}
.vx-float-field > label {
    position: absolute;
    top: -14px;
    color: #428bca;
    font-size: 0.8em;
}
.vx-float-field > input {
    border: 0;
    padding: 0.5em 0!important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;;
    border-bottom: 1px solid #555555;
    max-width: 400px;
    margin-top: 15px;
        background-color: transparent;
}

.vx-float-field > textarea {
    border: 0;
    padding: 0.5em 0!important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;;
    border-bottom: 1px solid #555555;
    max-width: 400px;
    margin-top: 15px;
    background-color: transparent;
}

.short-input {
  max-width: 400px;
  display: block;
}
