
/*!
 * Waves v0.4.1
 * https://publicis-indonesia.github.io/Waves
 *
 * Copyright 2014 Publicis Metro Indonesia, PT. and other contributors
 * Released under the BSD license
 * https://github.com/publicis-indonesia/Waves/blob/master/LICENSE
 */

.waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;

        z-index:999;
}

.waves-effect .waves-ripple {
    position: absolute;
    border-radius: 100%;
    border-width:0px;
    border-style:solid;
    width:0px;
    height:0px;
    opacity:0;
    border-color: rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
       z-index:999;
}

.waves-effect.waves-light .waves-ripple {
    border-color: rgba(255, 255, 255, 0.4);
}

.waves-notransition {
    -webkit-transition: none !important;
    transition: none !important;
}

.waves-button, 
.waves-icon {
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

a.waves-button,
a.waves-button:hover,
a.waves-button:visited,
a.waves-button:link,
button.waves-button,
input.waves-button-input {
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border:none;
    outline:none;
    background:transparent;
    color: inherit;
    font-size:14px;
    text-align: center;
    text-decoration:none;
}

.waves-button {
    padding:10px 15px;
    border-radius:2px;
}

.waves-button-input {
    margin:0;
    padding:10px 15px;
}

.waves-input-wrapper {
    border-radius:2px;
    vertical-align: bottom;
}

.waves-input-wrapper.waves-button {
    padding:0;
}

.waves-input-wrapper .waves-button-input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.waves-icon {
  text-align:center;
  width:2.5em;
  height:2.5em;
  line-height:2.5em;
  border-radius:50%;

}

.waves-float {
    -webkit-mask-image:none;
    -webkit-box-shadow:  0px 1px 1.5px 1px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 1px 1.5px 1px rgba(0, 0, 0, 0.12);
}

.waves-float:active {
    -webkit-box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.30);
    box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.30);
}

.waves-block {
    display:block;
}

/* Firefox Bug: link not triggered */
a.waves-effect .waves-ripple {
    z-index:-1;
}