/* ---------- GENERAL ---------- */
* {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*:before, *:after {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


#login-form h3 {
    margin: 0;
}

#login-form a {
    color: #999;
    text-decoration: none;
}

#login-form a:hover {
    color: #1dabb8;
}

#login-form fieldset {
    border: none;
    margin: 0;
}


.container {

}

/* ---------- LOGIN-FORM ---------- */
#login-form {
    width: 300px;
}

#login-form h3 {
    background-color: #282830;
    border-radius: 5px 5px 0 0;
    color: #fff;
    font-size: 14px;
    padding: 20px;
    text-align: center;
    text-transform: uppercase;
}

#login-form fieldset {
    background: #fff;
    border-radius: 0 0 5px 5px;
    padding: 20px;
    position: relative;
}

#login-form fieldset:before {
    background-color: #fff;
    content: "";
    height: 8px;
    left: 50%;
    margin: -4px 0 0 -4px;
    position: absolute;
    top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 8px;
}

#login-form input {
    font-size: 14px;
}

#login-form input[type="email"],
#login-form input[type="password"] {
    border: 1px solid #dcdcdc;
    padding: 12px 10px;
    width: 100%;
}

#login-form input[type="email"] {
    border-radius: 3px 3px 0 0;
}

#login-form input[type="password"] {
    border-top: none;
    border-radius: 0px 0px 3px 3px;
}

#login-form input[type="submit"] {
    background: #1dabb8;
    border-radius: 3px;
    color: #fff;
    float: right;
    font-weight: bold;
    margin-top: 20px;
    padding: 12px 20px;
}

#login-form input[type="submit"]:hover {
    background: #198d98;
}

#login-form footer {
    font-size: 12px;
    margin-top: 16px;
}

.info {
    background: #e5e5e5;
    border-radius: 50%;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    margin: 0 10px 0 0;
    text-align: center;
    width: 20px;
}
