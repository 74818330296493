
.flex-row {
    padding: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.flex-top {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;

}

.flex-row-wrap {
    padding: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.no-padding {
    padding: 0 !important;
}

.flex-vertical-center {
    margin-top: auto;
    margin-bottom: auto;
}

.flex-horizonal-center {
    margin-left: auto;
    margin-right: auto;
}

.flex-bottom {
    margin-top: auto;
}

.flex-end{
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.flex-right {
    margin-left: auto;
}

.flex-left {
    margin-right: auto;
}

.flex-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-direction: column;
    margin: 0 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.flex-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    /*min-height: 100%;*/
    height: inherit;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    min-height: 0;
}

.grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
}

.grow-1 {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.grow-2 {
    -webkit-box-flex: 2;
    -webkit-flex-grow: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
}

.grow-3 {
    -webkit-box-flex: 3;
    -webkit-flex-grow: 3;
    -ms-flex-positive: 3;
    flex-grow: 3;
}

.space-right {
    margin-right: 8px;
}

.space-left {
    margin-left: 8px;
}

.space-bottom {
    margin-bottom: 8px;
}

.space-top {
    margin-top: 8px;
}

.large-space-bottom {
    margin-bottom: 16px;
}

/* utilities.scss */

/* Flexbox Utility Classes */

/* Flex Container */
.d-flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

/* Justify Content */
.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

/* Align Items */
.align-start {
    align-items: flex-start;
}

.align-end {
    align-items: flex-end;
}

.align-center {
    align-items: center;
}

.align-baseline {
    align-items: baseline;
}

.align-stretch {
    align-items: stretch;
}

/* Align Content */
.align-content-start {
    align-content: flex-start;
}

.align-content-end {
    align-content: flex-end;
}

.align-content-center {
    align-content: center;
}

.align-content-between {
    align-content: space-between;
}

.align-content-around {
    align-content: space-around;
}

.align-content-stretch {
    align-content: stretch;
}

/* Flex Items */
.flex-grow {
    flex-grow: 1;
}

.flex-shrink {
    flex-shrink: 1;
}

.flex-no-shrink {
    flex-shrink: 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}
