﻿.vx-container-search-panel {
    margin: 5px;

}

.vx-list-container {
    margin-top: 15px;
    min-height: 50px;
    height: inherit;
}

.vx-search-item-employee-container {
    min-height: 60px;
    display: flex;
}