@font-face {
  font-family: 'RobotoDraft';
  font-style: normal;
  font-weight: 100;
  src: local('RobotoDraft Thin'), local('RobotoDraft-Thin'), url(RobotoDraft-Thin.woff2) format('woff2'), url(RobotoDraft-Thin.woff) format('woff');
}
@font-face {
  font-family: 'RobotoDraft';
  font-style: normal;
  font-weight: 300;
  src: local('RobotoDraft Light'), local('RobotoDraft-Light'), url(RobotoDraft-Light.woff2) format('woff2'), url(RobotoDraft-Light.woff) format('woff');
}
@font-face {
  font-family: 'RobotoDraft';
  font-style: normal;
  font-weight: 400;
  src: local('RobotoDraft'), local('RobotoDraft-Regular'), url(RobotoDraft-Regular.woff2) format('woff2'), url(RobotoDraft-Regular.woff) format('woff');
}
@font-face {
  font-family: 'RobotoDraft';
  font-style: normal;
  font-weight: 500;
  src: local('RobotoDraft Medium'), local('RobotoDraft-Medium'), url(RobotoDraft-Medium.woff2) format('woff2'), url(RobotoDraft-Medium.woff) format('woff');
}
@font-face {
  font-family: 'RobotoDraft';
  font-style: normal;
  font-weight: 700;
  src: local('RobotoDraft Bold'), local('RobotoDraft-Bold'), url(RobotoDraft-Bold.woff2) format('woff2'), url(RobotoDraft-Bold.woff) format('woff');
}
@font-face {
  font-family: 'RobotoDraft';
  font-style: normal;
  font-weight: 900;
  src: local('RobotoDraft Black'), local('RobotoDraft-Black'), url(RobotoDraft-Black.woff2) format('woff2'), url(RobotoDraft-Black.woff) format('woff');
}
@font-face {
  font-family: 'RobotoDraft';
  font-style: italic;
  font-weight: 400;
  src: local('RobotoDraft Italic'), local('RobotoDraft-Italic'), url(RobotoDraft-Italic.woff2) format('woff2'), url(RobotoDraft-Italic.woff) format('woff');
}
@font-face {
  font-family: 'RobotoDraft';
  font-style: italic;
  font-weight: 700;
  src: local('RobotoDraft Bold Italic'), local('RobotoDraft-BoldItalic'), url(RobotoDraft-BoldItalic.woff2) format('woff2'), url(RobotoDraft-BoldItalic.woff) format('woff');
}
