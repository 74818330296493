@import "../assets/fonts/fonts.css";
@import "../assets/css/font-awesome";
@import "../assets/css/normalize.scss";
@import "../assets/css/animate.scss";
@import "./flex-layout.scss";
@import "./vx-column-layout.scss";
// bootstrap
@import "../bower_components/mui/mui.css";
@import "../bower_components/bootstrap/dist/css/bootstrap.css";
@import "../../node_modules/angular-ui-bootstrap/dist/ui-bootstrap-csp.css";

@import "../bower_components/angular-loading-bar/src/loading-bar.css";
@import "../bower_components/nvd3/nv.d3.css";
@import "../bower_components/bootstrap-daterangepicker/daterangepicker-bs3.css";
@import "../bower_components/angular-multi-select/angular-multi-select.css";
@import "../bower_components/angular-toastr/dist/angular-toastr.css";
@import "../bower_components/ionicons/css/ionicons.css";
@import "../bower_components/ng-table/dist/ng-table.css";
@import "../bower_components/waves/dist/waves.css";
@import "../bower_components/angular-chart.js/dist/angular-chart.css";
@import "../../node_modules/angular-xeditable/dist/css/xeditable.css";
@import "../../node_modules/fullcalendar/dist/fullcalendar.css";
@import "../../node_modules/sweetalert/dist/sweetalert.css";
@import "../../node_modules/textangular/dist/textAngular.css";


@import "../assets/css/sb-admin.scss";
@import "../directives/vxWizard/vxWizard";
@import "../directives/vxTimeLine/vxTimeLine";
@import "../directives/vxRippleToggleButton/vxRippleToggleButton";
@import "../directives/vxInputLabel/vxInputLabel";
@import "../directives/vxFloatInput/vxFloatInputStyle";
@import "../directives/vxPanelGrid/vxPanelGrid";
@import "../directives/vxPanelGrid/panels/vxPanelKpi";
@import "../directives/vxPanelGrid/panels/vxPanelFilter";
@import "../itemtemplates/search/search-itemtemplates";


@import "./app.scss";
@import "./utils.scss";
@import '../pages/login/login.scss';
@import '../pages/documentation/documentation.scss';
@import "../assets/css/component.scss";
@import "../assets/css/timeline.scss";

// Import SCSS files from modules
@import "../modules/vxActionBar/vxActionBar.scss";
@import "../modules/vxAdress/vxAdress.scss";
@import "../modules/vxCustomer/vxCustomer.scss";
@import "../modules/vxEmployee/vxEmployee.scss";
@import "../modules/vxExtendablePanel/vxExtendablePanel.scss";
@import "../modules/vxJob/vxJob.scss";
@import "../modules/vxOrder/vxOrder.scss";
@import "../modules/vxList/vxList.scss";
@import "../modules/vxOverview/vxOverview.scss";
@import "../modules/vxPayment/vxPayment.scss";
@import "../modules/vxProduct/vxProduct.scss";
@import "../modules/vxProducts/vxProducts.scss";
@import "../modules/vxSearchPanel/vxSearchPanel.scss";
@import "leaflet/dist/leaflet.css";

// Your additional SCSS code goes here


/*
<link href="fonts/fonts.css" rel="stylesheet"/>
<link href="css/font-awesome.css" rel="stylesheet"/>
<link href="css/animate.css" rel="stylesheet"/>
<link href="flex-layout.css" rel="stylesheet"/>
<!-- Bootstrap -->
<link href="bower_components/mui/mui.css" rel="stylesheet" type="text/css"/>
<script src="bower_components/mui/mui.js"></script>
<link href="" rel="stylesheet"/>

<!--App-->
<link href="bower_components/angular-xeditable/dist/css/xeditable.css" rel="stylesheet"/>
<link href="bower_components/fullcalendar/dist/fullcalendar.css" rel="stylesheet"/>

<link href="css/sb-admin.css" rel="stylesheet"/>
<link href="directives/vxWizard/vxWizard.css" rel="stylesheet"/>
<link href="itemtemplates/search/search-itemtemplates.css" rel="stylesheet"/>
<!--Plugins-->
<link href="bower_components/sweetalert/lib/sweet-alert.css" rel="stylesheet"/>
<link href="bower_components/angular-loading-bar/src/loading-bar.css" rel="stylesheet"/>
<link href="bower_components/nvd3/nv.d3.css" rel="stylesheet"/>

<link href="bower_components/ng-table/dist/ng-table.css" rel="stylesheet"/>
<link href="bower_components/angular-multi-select/angular-multi-select.css" rel="stylesheet"/>
<link href="Pages/login/login.css" rel="stylesheet"/>
<link rel="stylesheet" href="bower_components/bootstrap-daterangepicker/daterangepicker-bs3.css"/>
<link rel="stylesheet" href="bower_components/angular-toastr/dist/angular-toastr.css"/>
<link rel="stylesheet" href="bower_components/ionicons/css/ionicons.css"/>
<link rel="stylesheet" href="bower_components/textAngular/src/textAngular.css"/>
<link rel="stylesheet" href="bower_components/angular-chart.js/dist/angular-chart.css"/>*/
