﻿ul[dnd-list] * {
    pointer-events: none;
}

ul[dnd-list] label.editable {
    pointer-events: all;
}
ul[dnd-list] i.hover-hand {
    pointer-events: all;
}

ul[dnd-list] button {
    pointer-events: all;
}

ul[dnd-list] a {
    pointer-events: all;
}

ul[dnd-list] {
    min-height: 50px;
}

ul[dnd-list], ul[dnd-list] > li {
    pointer-events: auto;
    position: relative;
}

.list-scroll {
    overflow-x: auto;
}

.avatar {
    height: 70px;
    width: 70px;
    overflow: hidden;
}
.avatar-sm {
    height: 30px;
    width: 30px;
    overflow: hidden;
}

ul[dnd-list] .dndDraggingSource {
    display: none;
}

ul[dnd-list] .dndPlaceholder {
    display: block;
    background-color: #ddd;
    min-height: 42px;
}
ul[dnd-list] li {
   position: relative;
}
