.timeline {
    position: relative;
    padding: 20px 0 20px;
    list-style: none;
}

.timeline:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 3px;
    margin-left: -1.5px;
    background-color: #eeeeee;
}

.timeline > li {
    position: relative;
    margin-bottom: 20px;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li > .timeline-panel {
    float: left;
    position: relative;
    width: 46%;
    padding: 10px 10px;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    -webkit-box-shadow: 0 1px 6px rgba(0,0,0,0.175);
    box-shadow: 0 1px 6px rgba(0,0,0,0.175);
}

.timeline > li > .timeline-panel:before {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 26px;
    right: -15px;
    border-top: 15px solid transparent;
    border-right: 0 solid #ccc;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #ccc;
}

.timeline > li > .timeline-panel:after {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 27px;
    right: -14px;
    border-top: 14px solid transparent;
    border-right: 0 solid #fff;
    border-bottom: 14px solid transparent;
    border-left: 14px solid #fff;
}

.timeline > li > .timeline-badge {
    z-index: 100;
    position: absolute;
    top: 16px;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    border-radius: 50% 50% 50% 50%;
    text-align: center;
    font-size: 1.4em;
    line-height: 50px;
    color: #fff;
    background-color: #999999;
}

.timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 10px 10px;
    padding-left:15px;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

.timeline-badge.primary {
    background-color: #2e6da4 !important;
}

.timeline-badge.success {
    background-color: #3f903f !important;
}

.timeline-badge.warning {
    background-color: #f0ad4e !important;
}

.timeline-badge.danger {
    background-color: #d9534f !important;
}

.timeline-badge.info {
    background-color: #5bc0de !important;
}

.timeline-title {
    margin-top: 0;
    margin-bottom: 5px;
    color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
    margin-bottom: 0;
}

.timeline-body > p + p {
    margin-top: 5px;
}

/* line 9, ../sass/ns-popover.scss */
.ns-popover-list-theme {
    box-sizing: border-box;
    border: solid 1px #d2d2d2;
    border-radius: 3px;
    z-index: 100;
    background-color: #fff;
    -webkit-box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2);
    box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2); }
/* line 19, ../sass/ns-popover.scss */
.ns-popover-list-theme ul, .ns-popover-list-theme .list {
    padding: 0;
    margin: 0.625rem 0;
    display: block; }
/* line 25, ../sass/ns-popover.scss */
.ns-popover-list-theme li, .ns-popover-list-theme .list-item {
    list-style-type: none; }
/* line 28, ../sass/ns-popover.scss */
.ns-popover-list-theme li a, .ns-popover-list-theme .list-item a {
    padding: 0.1875rem 0.625rem;
    display: block; }
/* line 32, ../sass/ns-popover.scss */
.ns-popover-list-theme li a:hover, .ns-popover-list-theme .list-item a:hover {
    background-color: #f5f5f5; }

/* line 124, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme {
    box-sizing: border-box;
    z-index: 100;
    background-color: transparent; }
/* line 129, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme .ns-popover-tooltip {
    box-sizing: border-box;
    border: solid 1px #d2d2d2;
    border-radius: 3px;
    background-color: #fff;
    padding: 0.5625rem 0.875rem;
    max-width: 20rem;
    font-size: 0.875rem;
    -webkit-box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2);
    box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2);
    margin: 10px; }
/* line 142, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme .ns-popover-tooltip ul {
    padding: 0;
    margin: 0.625rem 0;
    display: block; }
/* line 148, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme .ns-popover-tooltip li {
    list-style-type: none; }
/* line 151, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme .ns-popover-tooltip li a {
    padding: 0.1875rem 0.625rem;
    display: block; }
/* line 155, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme .ns-popover-tooltip li a:hover {
    background-color: #f5f5f5; }
/* line 163, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-bottom-placement .triangle {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: solid 11px;
    border-color: transparent;
    position: absolute;
    border-bottom-color: #d2d2d2;
    left: 50%;
    top: -11px;
    margin-left: -11px; }
/* line 57, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-bottom-placement .triangle:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border: solid 10px;
    border-color: transparent;
    position: absolute; }
/* line 87, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-bottom-placement .triangle:after {
    border-bottom-color: #fff;
    border-top-width: 0;
    top: 1px;
    margin-left: -10px; }
/* line 169, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-top-placement .triangle {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: solid 11px;
    border-color: transparent;
    position: absolute;
    border-top-color: #d2d2d2;
    left: 50%;
    bottom: -11px;
    margin-left: -11px; }
/* line 57, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-top-placement .triangle:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border: solid 10px;
    border-color: transparent;
    position: absolute; }
/* line 73, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-top-placement .triangle:after {
    border-top-color: #fff;
    border-bottom-width: 0;
    bottom: 1px;
    margin-left: -10px; }
/* line 175, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-right-placement .triangle {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: solid 11px;
    border-color: transparent;
    position: absolute;
    border-right-color: #d2d2d2;
    top: 50%;
    left: -11px;
    margin-top: -11px; }
/* line 57, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-right-placement .triangle:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border: solid 10px;
    border-color: transparent;
    position: absolute; }
/* line 115, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-right-placement .triangle:after {
    border-right-color: #fff;
    border-left-width: 0;
    left: 1px;
    bottom: -10px; }
/* line 181, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-left-placement .triangle {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: solid 11px;
    border-color: transparent;
    position: absolute;
    border-left-color: #d2d2d2;
    top: 50%;
    right: -11px;
    margin-top: -11px; }
/* line 57, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-left-placement .triangle:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border: solid 10px;
    border-color: transparent;
    position: absolute; }
/* line 101, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-left-placement .triangle:after {
    border-left-color: #fff;
    border-right-width: 0;
    right: 1px;
    bottom: -10px; }
/* line 187, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-left-align .ns-popover-tooltip {
    margin-left: 0; }
/* line 193, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-right-align .ns-popover-tooltip {
    margin-right: 0; }
/* line 199, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-top-align .ns-popover-tooltip {
    margin-top: 0; }
/* line 205, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-bottom-align .ns-popover-tooltip {
    margin-bottom: 0; }

/*# sourceMappingURL=nsPopover.css.map */