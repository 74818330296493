﻿.vx-container-produc {
  margin: 5px;
  margin-bottom: 10px;

}

.number-row {
  text-align: end;
  font-variant: tabular-nums;

  > div {
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }
}