
body {
  font-family: Helvetica, sans-serif;
  line-height: 1.2;
  vertical-align: middle;
  padding-top: 0 !important;
  color: #444444;
  margin: 0;
  overflow-x: hidden;
  font-size: 12px !important;
}

.employee-overview-small {

  .panel {
    margin-bottom: 3px !important;
  }

}

#side-menu li.has-children{
  &:hover {
    .dropdown-menu{
      display: block;
      top: 0;
      left: 60px;
    }
  }
  .dropdown-menu {

    top: 0;
    left: 60px;
  }
}

#side-menu li.dropdown {
  position: relative;
}


.chart-container{
  height: 100%;
  width: 100%;
  position: relative;
}


html {
  min-height: 100vh;
}

.vx-chart-container{
  .panel-body {
    display: block;
      height: calc(100% - 35px);

  }
  .flex-item{
    flex: 1 0 auto !important;
  }
}

.inner-tab > .nav-tabs {
  position: inherit !important;
  -webkit-box-shadow: unset;
  -moz-box-shadow: unset;
  box-shadow: unset;
}

.checkbox-upper {
  /*position: absolute;*/
  position: relative;
  margin-top: -3px;
  margin-left: -90px;
  font-size: 14px !important;
  /* margin-bottom: 10px; */
}

#ruler {
  visibility: hidden;
  white-space: nowrap;
}

.drop-box {
  background: #F8F8F8;
  border: 5px dashed #DDD;
  width: 200px;
  height: 65px;
  text-align: center;
  padding-top: 25px;
  margin: 10px;
}

.dragover {
  border: 5px dashed blue;
}

.hover-hand:hover {
  cursor: pointer;
}

.shell-container {
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
}

.icon-arrow-selected {
  margin: 0 30px;
}

.container-details {
  width: 100%;
}

#details-content {
  min-height: 100%;
  height: auto;
}

#details-content > .row {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

#details-content > .row [class*="col-lg"], #details-content > .row [class*="col-md"], #details-content > .row [class*="col-sm"] {
  float: none;
  display: inline-block;
}

.card {
  position: relative;
  z-index: 2;
  padding: 1.5rem;
  box-shadow: 0 1px 2px #aaa;
  background: #FCFCFC;
  margin: 0 1rem 1rem;
  border-radius: 3px;
  user-select: none;
  width: 300px;
  height: 215px;
  float: left;
}

.wrap-panel li {
}

.page-top-space {
  margin-top: 10px;
}

.small-negative-margin-left {
  margin-left: -5px;
}

.module-nav-active {
  background: white;
}

.module-nav {
  padding: 10px 0 5px 0;
  cursor: pointer;
}

.vx-label-h2 > div > span {
  font-size: 30px;
}

.vx-light-gray {
  color: #929CA6;
}

.vx-gray {
  color: #4D4D4E;
}

.vx-blue {
  color: #153F60;
}

.vx-blue-bg {
  background-color: #153F60;
}

.vx-orange {
  color: #EF7123;
}

.vx-orange-bg {
  background-color: #EF7123 !important;
}

.red-bg {
  background-color: #d9534f;
}

.green {
  color: #5cb85c;
}

.green-bg {
  background-color: #5cb85c;
}

.yellow-bg {
  background-color: #f0ad4e;
}

.red {
  color: #d9534f;
}

.green {
  color: limegreen;
}

.yellow {
  color: #f0ad4e;
}

.font-white {
  color: #ffffff;
}

.vx-a-highlight {
  border-left: 8px #EF7123 solid;
}

.vx-a-highlight-red {
  border-left: 8px #d9534f solid;
}

.vx-a-highlight-yellow {
  border-left: 8px #f0ad4e solid;
}

.vx-a-highlight-green {
  border-left: 8px #5cb85c solid;
}

.vx-a-highlight-blue {
  border-left: 8px #153F60 solid;
}

.vx-a-highlight-gray {
  border-left: 8px #4D4D4E solid;
}

/*
fullcalendar
*/

.fc-day-grid-event .fc-content {
  white-space: normal;
  overflow: visible;
}

.ignore-padding-left {
  padding-left: 0 !important;
}

.ignore-padding-right {
  padding-right: 0 !important;
}

.ignore-padding {
  padding: 0 !important;
}

.ignore-padding {
  padding: 0 0 0 5px !important;
}

.top-space {
  margin-top: 5px;
}

.panel-overflow {
  overflow-y: auto;
}

.panel {
 // min-width: 300px;
  width: 100%;
}

.panel-reset-width {
  min-width: 0;
}

.top-space-large {
  margin-top: 10px;
}

.space-right-childeren > small,
.space-right-childeren > span {
  margin-right: 8px;
}

.angular-google-map-container {
  height: 250px;
  top: 0;
  left: 0;
}

.customer-image {
  height: 100px;
  width: 100px;
  border: solid 1px;
  border-radius: 10px;
}

.editable-empty {
  color: red !important;
}

.editable-click, label.editable-click {
  color: green;
  border-bottom: none 0;
}

.editable-click:hover, label.editable-click:hover {
  color: #47a447;
  border-bottom: none 0;
  cursor: pointer;
}

.ng-pageslide {
  background: #eee;
  margin-top: 76px;
  margin-right: 17px;
  position: relative;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.175);
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.175);
  -box-shadow: 0 2px 6px rgba(0, 0, 0, 0.175);
}

//* basic styling for entering and leaving */
/* left and right added to ensure full width */
.slidingContent.ng-enter,
.slidingContent.ng-leave {
  position: absolute;
}


/* enter animation */
.slidingContent.ng-enter {
  animation: fadeIn 0.4s both;
}

/* leave animation */
.slidingContent.ng-leave {
  animation: fadeOut 0.4s both;
}

/* Fade in keyframes with Material Design easing */
@keyframes fadeIn {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
  }
  100% {
    opacity: 1;
  }
}

/* Fade out keyframes with Material Design easing */
@keyframes fadeOut {
  0% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
  }
  100% {
    opacity: 0;
  }
}

.body-container {
  height: auto;
  min-height: 100%;
}

.topbar-container {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ddd;
  padding-top: 7px;
  padding-bottom: 8px;
  max-height: 51px;
  position: fixed;
  top: 0;
  z-index: 9999;
}

.logo-container {
  margin-left: 8px;
  display: inline-block;
  vertical-align: middle;
  float: left;
}

.logo-container > img {
  background-size: auto;
  /* margin-top: 6px; */
  /* width: 72px; */
  margin-left: 11px;
  height: 30px;
}

.search-container {
  position: relative;
  padding: 0;
  margin-top: 3px;
  margin-left: 22px;
  overflow: hidden;
  max-width: 650px;
  float: left;
}

.google-input {
  min-height: 28px;
  min-width: 400px;
  padding: 0 8px;
  border: 1px solid #c0c0c0 !important;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) !important;
  -ms-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) !important;
}

.clear-btn {
  margin-top: 6px;
  margin-left: -15px;
  position: absolute;
}

.dropdown-menu {
  z-index: 9999 !important;
}

.notification-menu{
  max-height: 600px;
  min-width: 250px;
  overflow: auto;
}

.filterbar-employee{
  .date-picker{
    width: 100%;
    padding-right: 10px;
    > p {
      width: 100%;
    }
  }
}

.extra-filter-container{
  .checkboxLayer.show{
    right: 0;
  }
}

.no-min-width {
  min-width: 0 !important;
}

.ignore-top-margin {
  margin-top: 0 !important;
}

.ignore-bottom-margin {
  margin-bottom: 0 !important;
}

.ignore-margin {
  margin: 0 !important;
}

.ignore-left-margin {
  margin-left: 0 !important;
}

.ignore-right-margin {
  margin-right: 0 !important;
}

.md-min-width {
  min-width: 375px !important;
}

.lg-min-width {
  min-width: 450px !important;
}

.lg-max-width {
  max-width: 650px !important;
}

.sm-max-width {
  max-width: 150px !important;
}

.large-negative-margin-bottom {
  margin-bottom: -25px;
}

.small-negative-margin-bottom {
  margin-bottom: -8px;
}

.small-negative-margin-top {
  margin-top: -8px;
}

.circle-text {
  width: 50%;
}

.circle-text:after {
  content: "";
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background: #4679BD;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.circle-text div {
  float: left;
  width: 100%;
  padding-top: 50%;
  line-height: 1em;
  margin-top: -0.5em;
  text-align: center;
  color: white;
  font-size: 36pt;
}

.google-button {
  padding: 0 24px;
  margin: 0 0px;
  min-height: 28px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #4387f5;
  background-image: -moz-linear-gradient(top, #4d90fe, #357ae8);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#4d90fe), to(#357ae8));
  background-image: -webkit-linear-gradient(top, #4d90fe, #357ae8);
  background-image: -o-linear-gradient(top, #4d90fe, #357ae8);
  background-image: linear-gradient(to bottom, #4d90fe, #357ae8);
  background-repeat: repeat-x;
  border-color: #357ae8 #357ae8 #1555bb;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.1);
}

.below-topbar {
}

.below-topbar-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-bottom: -51px;
}

.below-topbar-canvas {
  margin-top: 51px;
  width: 100%;
  height: 100%;
  position: relative;
  -moz-transform: translate3d(230px, 0, 0);
  -ms-transform: translate3d(230px, 0, 0);
  -o-transform: translate3d(230px, 0, 0);
  -webkit-transform: translate3d(230px, 0, 0);
  transform: translate3d(230px, 0, 0);
  -moz-animation-delay: 10s;
  -o-animation-delay: 10s;
  -webkit-animation-delay: 10s;
  animation-delay: 10s;
  -moz-transition: .3s ease all;
  -o-transition: .3s ease all;
  -webkit-transition: .3s ease all;
  transition: .3s ease all;
}

.main-side-left {
  border-right: solid 1px #ddd;
  margin: 0;
  width: 60px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  padding: 0;
  z-index: 99;
}

.side-menuwrapper {
  margin-top: 45px;
}

#version {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-height: 600px) and (max-height: 800px) {
  .ta-editor.ta-html, .ta-scroll-window.form-control {
    height: 125px !important;
    min-height: 125px !important;
  }

  .ta-editor.ta-html, .ta-scroll-window.form-control {
    min-height: 125px;
    height: auto;
    overflow: auto;
    font-family: inherit;
    font-size: 100%;
  }

  .ta-scroll-window > .ta-bind {
    height: 125px !important;
    min-height: 125px !important;
    padding: 6px 12px;
  }

  .angular-google-map-container {
    height: 125px !important;
    top: 0;
    left: 0;
  }

  .worknotes.panel-overflow {
    overflow-y: hidden;
  }

  .switch-to-row-when-small {
    flex-wrap: nowrap !important;
    flex-direction: row !important;
  }

  .switch-to-column-when-small {
    flex-wrap: nowrap !important;
    flex-direction: column !important;
  }
}

@media (min-height: 800px) {
  .ta-editor.ta-html, .ta-scroll-window.form-control {
    height: auto;
    max-height: 300px;
  }

  .panel-overflow {
    overflow-y: hidden;
  }
}

@media (min-height: 50px) and (max-height: 550px),
(min-width: 50px) and (max-width: 750px) {

  .small-input {
    min-width: 30px !important;
  }
  .small_padding {
    padding-left: 5px !important;
  }
  .hide-topavatar {
    display: none !important;
  }

  .search-container {
    margin-left: 5px !important;
  }

  .main-side-left {
    height: 40px;
    width: 100%;
    bottom: 0;
    top: auto;
    background: #fff;
    border-top: 1px solid #ddd;
    position: fixed;
  }

  .lg-min-width {
    min-width: 0 !important;
  }

  div[nice-scroll] {
    overflow: visible !important;
    outline: none !important;;
    height: auto !important;;
  }

  .actionbar-canvas > div.flex-row {
    flex-wrap: wrap;
  }

  .actionbar-wrapper {
    position: relative;
    overflow-y: auto !important;
    overflow-x: auto !important;
    width: 100%;
    padding-bottom: 35px;
  }

  .employee-overview-small > div > div {
    width: auto !important;

  }



  /*.actionbar-wrapper.page-height2 {*/
  /*padding-right: 60px;*/
  /*}*/
  .flex-column {
    flex: 1 1 auto !important;
  }

  .flex-row {
    flex-wrap: wrap !important;
  }

  .panel-body {
    padding: 10px;
  }

  .google-input {
    min-width: 250px;
  }

  .page-height {
    height: 100% !important;
  }

  .page-height2 {
    height: 100% !important;
  }

  #version {
    display: none;
  }

  #side-menu {
    display: flex;
  }

  .side-menuwrapper {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  #page-wrapper {
    margin-left: 0 !important;
  }
}

.full-height {
  height: calc(100vh - 225px);
}

.full-width {
  width: 100%;
}

.main-content {
  margin: 0;
  padding: 5px;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  overflow: auto;
  margin-left: 30px;
}

.main-side-left-content {
  width: 200px;
  height: 100%;
  float: left;
}

.main-side-left-buttons {
  width: 30px;
  height: 100%;
  float: right;
}

.nav-tabs {
  border: 0 !important;
  position: fixed;
  z-index: 98;
  background-color: white;
  width: 100%;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.175);
  -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.175);
  -box-shadow: 0 2px 6px rgba(0, 0, 0, 0.175);
}

.nav-tabs > li > a {
  border: 0 !important;
  border-radius: 0 !important;
  padding-bottom: 7px;
  margin-right: 0 !important;
}

.nav-tabs > li.active > a {
  background-color: rgba(255, 165, 0, .45) !important;
}

.nav-tabs > li:hover {
  background-color: rgba(255, 165, 0, .15) !important;
}

.nav-tabs > li > a:hover {
  background-color: transparent;
}

a {
  color: #5f6f81;
}

.nav-item-left-side {
  color: #5f6f81;
}

.page-content {
  padding-top: 50px;
  background-color: transparent;
  padding-left: 15px;
  padding-right: 15px;
}

.details-content {
  padding-top: 34px;
  padding-left: 0;
  padding-right: 0;
}

.panel {
  -webkit-box-shadow: rgb(170, 170, 170) 0 1px 2px 0;
  -ms-box-shadow: rgb(170, 170, 170) 0 1px 2px 0;
  box-shadow: rgb(170, 170, 170) 0 1px 2px 0;
}

.wrapword {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* css-3 */
  -ms-word-wrap: break-word;
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  -ms-word-break: break-all;
  word-break: break-all;
  white-space: normal;
}

.panel-heading {
  border-bottom: 1px solid rgb(229, 229, 229) !important;
}

.panel-heading > p {
  margin-bottom: 0;
}


.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 2px;
  font-size: 12px !important;
  line-height: 1;
  vertical-align: middle;
}


.selected-language-icon {
  > img {
    width: 20px;
  }
}

.navbar-top-links {
  margin-right: 0;
}

.navbar-top-links li {
  display: inline-block;
}

.navbar-top-links li:last-child {
  margin-right: 0;
}

.navbar-top-links li a {
  padding: 15px;
  min-height: 50px;
}

.navbar-top-links .dropdown-menu li {
  display: block;
}

.navbar-top-links .dropdown-menu li:last-child {
  margin-right: 0;
}

.navbar-top-links .dropdown-menu li a {
  padding: 3px 20px;
  min-height: 0;
}

.navbar-top-links .dropdown-menu li a div {
  white-space: normal;
}

.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks,
.navbar-top-links .dropdown-alerts {
  width: 310px;
  min-width: 0;
}

.navbar-top-links .dropdown-messages {
  margin-left: 5px;
}

.navbar-top-links .dropdown-tasks {
  margin-left: -59px;
}

.navbar-top-links .dropdown-alerts {
  margin-left: -123px;
}

.navbar-top-links .dropdown-user {
  right: 0;
  left: auto;
}

.email-body {
  overflow-wrap: break-word;
  overflow-y: hidden;
}

.cssFadeList.ng-hide {
  opacity: 0;
}

.cssFadeList {
  transition: 0.5s ease all;
  opacity: 1;
  transition-delay: 0.6s;
}

.cssFade {
  transition: 0.5s ease all;
  opacity: 1;
}

.cssFade.ng-hide {
  opacity: 0;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
}

.loading i {
  position: absolute;
  top: 24px;
  left: 24px;
  display: block;
  width: 24px;
  height: 24px;
  background: #799ED2;
  border-radius: 24px;
  -webkit-animation: spin2 1.5s ease-in-out infinite;
}

.loading i:first-child {
  top: -24px;
  left: 0;
  background: #194685;
  -webkit-animation: spin 1.5s ease-in-out infinite;
}

.loading i:last-child {
  top: 24px;
  left: -24px;
  background: #C7202C;
  -webkit-animation: spin3 1.5s ease-in-out infinite;
}

@-webkit-keyframes spin {
  0% {
    top: -24px;
    left: 0;
    -webkit-transform: scale(1);
  }

  17% {
    -webkit-transform: scale(.5);
  }

  33% {
    top: 24px;
    left: 24px;
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(.5);
  }

  66% {
    top: 24px;
    left: -24px;
    -webkit-transform: scale(1);
  }

  83% {
    -webkit-transform: scale(.5);
  }

  100% {
    top: -24px;
    left: 0;
    -webkit-transform: scale(1);
  }
}

@-webkit-keyframes spin2 {
  0% {
    top: 24px;
    left: 24px;
    -webkit-transform: scale(1);
  }

  17% {
    -webkit-transform: scale(.5);
  }

  33% {
    top: 24px;
    left: -24px;
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(.5);
  }

  66% {
    top: -24px;
    left: 0;
    -webkit-transform: scale(1);
  }

  83% {
    -webkit-transform: scale(.5);
  }

  100% {
    top: 24px;
    left: 24px;
    -webkit-transform: scale(1);
  }
}

@-webkit-keyframes spin3 {
  0% {
    top: 24px;
    left: -24px;
    -webkit-transform: scale(1);
  }

  17% {
    -webkit-transform: scale(.5);
  }

  33% {
    top: -24px;
    left: 0;
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(.5);
  }

  66% {
    top: 24px;
    left: 24px;
    -webkit-transform: scale(1);
  }

  83% {
    -webkit-transform: scale(.5);
  }

  100% {
    top: 24px;
    left: -24px;
    -webkit-transform: scale(1);
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.multiSelect .button {
  font-size: 12px !important;
}

.label {
  font-size: 90%;
}

.table-hover > tbody > tr:hover {
  background-color: #FFEBCF;
}

.row-selected {
  background-color: #C8F1B7 !important;
}

/* line 9, ../sass/ns-popover.scss */
.ns-popover-list-theme {
  box-sizing: border-box;
  border: solid 1px #d2d2d2;
  border-radius: 3px;
  z-index: 100;
  background-color: #fff;
  -webkit-box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2);
}

/* line 19, ../sass/ns-popover.scss */
.ns-popover-list-theme ul, .ns-popover-list-theme .list {
  padding: 0;
  margin: 0.625rem 0;
  display: block;
}

/* line 25, ../sass/ns-popover.scss */
.ns-popover-list-theme li, .ns-popover-list-theme .list-item {
  list-style-type: none;
}

/* line 28, ../sass/ns-popover.scss */
.ns-popover-list-theme li a, .ns-popover-list-theme .list-item a {
  padding: 0.1875rem 0.625rem;
  display: block;
}

/* line 32, ../sass/ns-popover.scss */
.ns-popover-list-theme li a:hover, .ns-popover-list-theme .list-item a:hover {
  background-color: #f5f5f5;
}

/* line 124, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme {
  box-sizing: border-box;
  z-index: 100;
  background-color: transparent;
}

/* line 129, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme .ns-popover-tooltip {
  box-sizing: border-box;
  border: solid 1px #d2d2d2;
  border-radius: 3px;
  background-color: #fff;
  padding: 0.5625rem 0.875rem;
  max-width: 20rem;
  font-size: 0.875rem;
  -webkit-box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2);
  margin: 10px;
}

/* line 142, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme .ns-popover-tooltip ul {
  padding: 0;
  margin: 0.625rem 0;
  display: block;
}

/* line 148, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme .ns-popover-tooltip li {
  list-style-type: none;
}

/* line 151, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme .ns-popover-tooltip li a {
  padding: 0.1875rem 0.625rem;
  display: block;
}

/* line 155, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme .ns-popover-tooltip li a:hover {
  background-color: #f5f5f5;
}

/* line 163, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-bottom-placement .triangle {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: solid 11px;
  border-color: transparent;
  position: absolute;
  border-bottom-color: #d2d2d2;
  left: 50%;
  top: -11px;
  margin-left: -11px;
}

/* line 57, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-bottom-placement .triangle:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border: solid 10px;
  border-color: transparent;
  position: absolute;
}

/* line 87, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-bottom-placement .triangle:after {
  border-bottom-color: #fff;
  border-top-width: 0;
  top: 1px;
  margin-left: -10px;
}

/* line 169, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-top-placement .triangle {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: solid 11px;
  border-color: transparent;
  position: absolute;
  border-top-color: #d2d2d2;
  left: 50%;
  bottom: -11px;
  margin-left: -11px;
}

/* line 57, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-top-placement .triangle:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border: solid 10px;
  border-color: transparent;
  position: absolute;
}

/* line 73, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-top-placement .triangle:after {
  border-top-color: #fff;
  border-bottom-width: 0;
  bottom: 1px;
  margin-left: -10px;
}

/* line 175, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-right-placement .triangle {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: solid 11px;
  border-color: transparent;
  position: absolute;
  border-right-color: #d2d2d2;
  top: 50%;
  left: -11px;
  margin-top: -11px;
}

/* line 57, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-right-placement .triangle:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border: solid 10px;
  border-color: transparent;
  position: absolute;
}

/* line 115, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-right-placement .triangle:after {
  border-right-color: #fff;
  border-left-width: 0;
  left: 1px;
  bottom: -10px;
}

/* line 181, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-left-placement .triangle {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: solid 11px;
  border-color: transparent;
  position: absolute;
  border-left-color: #d2d2d2;
  top: 50%;
  right: -11px;
  margin-top: -11px;
}

/* line 57, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-left-placement .triangle:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border: solid 10px;
  border-color: transparent;
  position: absolute;
}

/* line 101, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-left-placement .triangle:after {
  border-left-color: #fff;
  border-right-width: 0;
  right: 1px;
  bottom: -10px;
}

/* line 187, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-left-align .ns-popover-tooltip {
  margin-left: 0;
}

/* line 193, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-right-align .ns-popover-tooltip {
  margin-right: 0;
}

/* line 199, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-top-align .ns-popover-tooltip {
  margin-top: 0;
}

/* line 205, ../sass/ns-popover.scss */
.ns-popover-tooltip-theme.ns-popover-bottom-align .ns-popover-tooltip {
  margin-bottom: 0;
}

// fix chrome form issue
.autofields{
  .form-control{
    position: relative;
  }
}