﻿.vx-container-order{
    padding: 15px;
    padding-top: 0;
    padding-bottom: 0;
    margin:5px;
    margin-bottom: 0px;
    .status-label {
        i {
            color: white;
            margin-right: 8px;
            font-size: 11px;
        }

        &.editable-click {
            color: white;
            border-bottom: 0;
        }

        &.editable-click:hover {
            color: whitesmoke;
        }

        &.ignore-focus-ring {
            outline: none;
        }
    }
}




