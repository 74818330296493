/* utilities.scss */
/* Margin Utility Classes */
.mt-0 { margin-top: 0; }
.mb-0 { margin-bottom: 0; }
.ml-0 { margin-left: 0; }
.mr-0 { margin-right: 0; }
.mx-0 { margin-left: 0; margin-right: 0; }
.my-0 { margin-top: 0; margin-bottom: 0; }
.m-0 { margin: 0; }

@for $i from 1 through 5 {
  .mt-#{$i} { margin-top: $i * 0.5rem; }
  .mb-#{$i} { margin-bottom: $i * 0.5rem; }
  .ml-#{$i} { margin-left: $i * 0.5rem; }
  .mr-#{$i} { margin-right: $i * 0.5rem; }
  .mx-#{$i} { margin-left: $i * 0.5rem; margin-right: $i * 0.5rem; }
  .my-#{$i} { margin-top: $i * 0.5rem; margin-bottom: $i * 0.5rem; }
}

/* Padding Utility Classes */
.p-0 { padding: 0; }
.pt-0 { padding-top: 0; }
.pb-0 { padding-bottom: 0; }
.pl-0 { padding-left: 0; }
.pr-0 { padding-right: 0; }
.px-0 { padding-left: 0; padding-right: 0; }
.py-0 { padding-top: 0; padding-bottom: 0; }

@for $i from 1 through 5 {
  .pt-#{$i} { padding-top: $i * 0.5rem; }
  .pb-#{$i} { padding-bottom: $i * 0.5rem; }
  .pl-#{$i} { padding-left: $i * 0.5rem; }
  .pr-#{$i} { padding-right: $i * 0.5rem; }
  .px-#{$i} { padding-left: $i * 0.5rem; padding-right: $i * 0.5rem; }
  .py-#{$i} { padding-top: $i * 0.5rem; padding-bottom: $i * 0.5rem; }
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
