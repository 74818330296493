﻿
.vx-container-adress {
  margin: 5px;
  margin-bottom: 10px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  height: 100%;


  .map-container {

    width: 100%;
    flex: 1 0 auto;
    min-height: 0px;
  }

  // remove flag of openstreet map
  .leaflet-control-attribution {
    display: none;
  }
}

.setting-company-panel > div {
    height: 100%;
  }

.setting-company-panel > div > ng-include {
  height: 100%;
  display: block;
  }

.setting-company-panel_content{
  height: 100%;

}

.vx-adress-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 45px);
}

vx-adress {
  height: 100%;
}