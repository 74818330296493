﻿.img-circle {
  position: relative;
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.img-circle img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out;
}

.img-circle::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease-in-out;
}

.img-circle:hover::after {
  background-color: rgba(0, 0, 0, 0.3);
}

.img-circle:hover img {
  transform: scale(1.1);
}