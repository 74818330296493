.container {
    display: flex;
    height: inherit;
}
.search-item{
    border-bottom: 1px solid #d4d4d4;
    padding: 8px 16px;
    small{
        margin-top: -6px;
        display: block;
    }
}
.searchpanel {
    min-height: 75px;
    min-width: 250px;
    display: flex;
    > .vx-row{
        padding-bottom: 8px;
        padding-top: 8px;
    }
}

.s-left-column {
    min-width: 80px;
}

.sm-left-column {
    min-width: 40px;
}

.center {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.bottom {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: flex-end;
    align-items: flex-end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.s-right-column {
    min-width: 70px;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0px;
    padding: 5px;
}

.grow-1-column {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0px;
    padding: 5px;
}

.grow-0-column {
    min-width: 60px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 0px;
    padding: 5px;
}

.label-max-height {
    max-height: 16px !important;
    min-width: 65px;
}

.wrap {
    word-break: normal;
    white-space: normal;
}

.flex-wrap {
    flex-wrap: wrap !important;
}
.flex-element {
    display: flex;
}