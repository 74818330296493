﻿.vx-container-extendablepanel {
    display: flex;
    min-height: 100%;
    flex: 0 1 auto;
    align-items: stretch;
}

.vx-panel-full-height {
    min-height: 100%;

}

.float-panel {
    float: none;
    display: inline-block;
    overflow: hidden;
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-right: 15px;
}

.clearfix:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}
