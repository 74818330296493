.kpi-panel-container {
  background-color: #fff;
  //border: 1px solid #ddd;
  border-radius: 6px;
  //padding: 1rem;
  //display: inline-block;
  //min-width: 180px;
  text-align: left;
  color: #000;  // Main text color

  .kpi-title {
    font-size: 0.875rem; // ~14px
    font-weight: 500;
    color: #333;
    margin-bottom: 0.25rem;
  }

  .kpi-value {
    font-size: 2rem; // ~32px
    font-weight: 600;
    margin-bottom: 0.25rem;
    color: #000;  // Larger text color
  }
  .kpi-panel-container {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 1rem;
    display: inline-block;
    min-width: 180px;
    text-align: left;
    color: #000;  // Main text color

    .kpi-title {
      font-size: 0.875rem; // ~14px
      font-weight: 500;
      color: #333;
      margin-bottom: 0.25rem;
    }

    .kpi-value {
      font-size: 2rem; // ~32px
      font-weight: 600;
      margin-bottom: 0.25rem;
      color: #000;  // Larger text color
    }

    .kpi-change {
      display: inline-flex;
      align-items: center;
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 0.25rem;

      &.positive {
        color: #4CAF50; // Green for positive
      }

      &.negative {
        color: #F44336; // Red for negative
      }

      .arrow {
        display: inline-block;
        width: 0;
        height: 0;
        margin-right: 0.25rem;

        &.arrow-up {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 6px solid currentColor;
        }

        &.arrow-down {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 6px solid currentColor;
        }
      }
    }

    .kpi-timeframe {
      font-size: 0.75rem; // ~12px
      color: #666;        // Slightly lighter text
    }
  }

  .kpi-value-container{
    display: flex;
    .kpi-value{
      margin-right: 0.5rem;
    }
  }

  .kpi-change {
    display: inline-flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.25rem;

    &.positive {
      color: #4CAF50; // Green for positive
    }

    &.negative {
      color: #F44336; // Red for negative
    }

    .arrow {
      display: inline-block;
      width: 0;
      height: 0;
      margin-right: 0.25rem;

      &.arrow-up {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 6px solid currentColor;
      }

      &.arrow-down {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid currentColor;
      }
    }
  }

  .kpi-timeframe {
    font-size: 0.75rem; // ~12px
    color: #666;        // Slightly lighter text
  }
}
