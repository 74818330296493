.filter-panel{

  form{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .autofields{
      width: 100%;
    }

    .filter-button{
      margin-left: 30px;
    }
  }
}