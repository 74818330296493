﻿.vx-container-actionbar {
    margin: 5px;
}

.fade-color {
    -moz-transition: all .7s ease-in;
    -o-transition: all .7s ease-in;
    -webkit-transition: all .7s ease-in;
    transition: all .7s ease-in;
}

.actionbar {
    border-right: solid 1px #ddd;
    margin-right: 10px;
    width: 375px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -375px;
    padding: 20px;
    background-color: white;
}

.page-height {
    height: calc(100vh - 50px);
}

.page-height2 {
    height: calc(100vh - 84px);
}

.actionbar-wrapper {
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    /*padding-bottom: 35px;*/
}

.actionbar-canvas {
    width: 100%;
    height: 100%;
    position: relative;
    margin-left: 60px;
    margin-bottom: 0px;
    /*margin-top: 34px;*/
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
}
.actionbar-nosidepanel {
    margin-left: 0 !important;
}

.actionbar-wrapper.show-nav .actionbar-canvas {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
    -webkit-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0);
}

.actionbar-scroll {
    background-color: white;
    min-height: 100%;
    min-width: 55px;
}

.actionbar-scroll-content {
    float: left;
    width: 0;

}
